<template>
  <div>
    <section class="payment-info-page">
      <b-container>
        <b-row>
          <b-col>
            <div class="title">
              <h2>{{ $t("payment_cancel") }}</h2>
              <span>{{ $t("something_wrong") }}</span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-between align-items-center">
          <b-col lg="6">
            <div class="left-content">
              <div v-if="transactionId != null">
                Transaction: {{ "#" + transactionId }}
              </div>
              <b-link to="/" class="btn btn-primary w-full">{{
                $t("try_again")
              }}</b-link>
              <div class="note">
                <label>{{ $t("payment_cancel_title") }}</label>
                <p>{{ $t("payment_cancel_info") }}</p>
              </div>
            </div>
          </b-col>
          <b-col lg="3">
            <cmsBlockSimple
              v-if="typeof contactInfoCms == 'object'"
              :identifier="contactInfoCms.identifier"
            />
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "PaymentSuccess",
  components: {
    cmsBlockSimple,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: this.$t("payment_cancel") },
        { name: "keywords", content: this.$t("payment_cancel") },
        { name: "description", content: this.$t("payment_cancel") },
      ],
      title: this.$t("payment_cancel"),
    };
  },
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
    contactInfoCms() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "customer_service_contact_info"
      );
    },
  },
  mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    Logger.debug("router", "mounted", this.$store.state.route)();
  },

  data: () => ({
    sidebarData: {
      heading: "Categorie",
      options: [
        { displayName: "Gentlemen", linkTo: "#", active: false },
        { displayName: "Women", linkTo: "#", active: false },
        { displayName: "Bicycle clothing sets", linkTo: "#", active: false },
        { displayName: "Children", linkTo: "#", active: false },
        { displayName: "Cycling shoes", linkTo: "#", active: false },
        { displayName: "For your bike", linkTo: "#", active: false },
        { displayName: "For your body", linkTo: "#", active: false },
        { displayName: "Brands", linkTo: "#", active: false },
        { displayName: "Cycling books", linkTo: "#", active: false },
        { displayName: "Gift voucher", linkTo: "#", active: false },
      ],
    },
  }),
};
</script>

<style lang="scss" scoped>
@import "@/base/scss/variables.scss";
.payment-info-page {
  padding: 20px 0px 30px 0px;
  .title {
    border-bottom: 1px solid $borderLightColor;
    padding-bottom: 10px;
    margin-bottom: 10px;
    h2 {
      font-size: 22px;
      margin-bottom: 2px;
    }
    span {
      font-size: 14px;
    }
  }
  .note {
    margin-top: 30px;
    font-size: 14px;
    label {
      font-weight: 600;
      margin-bottom: 5px;
    }
    p {
      line-height: 22px;
      margin-bottom: 0;
    }
  }
}
</style>
